<template>
  <div>
    <b-card>
      <!-- Cards -->
      <b-row>
        <h1 class="ml-2 ">Kullanıcılar</h1>
        <!-- Yeni Ekle -->
        <b-button
          v-if="addNew"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          class="mr-1 mb-2 d-flex fload-right ml-auto"
          v-b-modal.modal-addNew
          @click="clearData()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-row>
      <b-row>
        <b-col v-for="(item, index) in TabloItems" :key="index" md="12">
          <b-card class="mb-2 cardStyle">
            <b-card-body>
              <b-row>
                <b-col md="5">
                  <h5>Email</h5>
                  <hr />
                  <div v-if="item.email">
                    <b-card-text class="titleStyle"
                      >{{ item.email }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col md="5">
                  <h5>Password</h5>
                  <hr />
                  <div v-if="item.password">
                    <b-card-text>
                      <div>*********</div>
                    </b-card-text>
                  </div>
                </b-col>

                <b-col md="2">
                  <div>
                    <b-button
                      @click="editModal(item)"
                      v-b-modal.modal-edit
                      variant="gradient-success"
                      class="btn-icon mr-1"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      @click="deleteModal(item)"
                      v-b-modal.modal-danger
                      variant="gradient-danger"
                      class="btn-icon"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- Edit Modal-->
      <b-modal
        id="modal-edit"
        title="Düzenle"
        cancel-variant="outline-secondary"
        size="lg"
        centered
        hide-footer
      >
        <b-form>
          <b-form>
            <b-form-group label="Email" label-for="name">
              <b-form-input v-model="email" />
            </b-form-group>
            <b-form-group label="Password" label-for="name">
              <b-form-input v-model="password" />
            </b-form-group>
          </b-form>

          <b-form-group>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success float-right"
              class="mt-3"
              @click="updateBtn()"
            >
              Kaydet
            </b-button>
          </b-form-group>
        </b-form>
      </b-modal>
      <!-- Add New Modal -->
      <b-modal
        id="modal-addNew"
        title="Yeni Ekle"
        ok-only
        ok-title="Kaydet"
        centered
        @ok="saveButton()"
        size="s"
      >
        <b-form>
          <b-form-group label="Email" label-for="name">
            <b-form-input v-model="email" />
          </b-form-group>
          <b-form-group label="Password" label-for="name">
            <b-form-input v-model="password" />
          </b-form-group>
        </b-form>
      </b-modal>
      <!-- Delete Modal-->
      <b-modal
        id="modal-danger"
        ok-only
        ok-variant="danger"
        ok-title="Ok"
        no-close-on-backdrop
        modal-class="modal-danger"
        centered
        @ok="deleteData()"
      >
        <b-card-text class="mt-1">
          SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ?
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardText,
  BCardTitle,
  BForm,
  BMedia,
  BCardBody,
  BRow,
  BModal,
  BAvatar,
  BMediaBody,
  BCol,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BMediaAside,
  BProgress,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import Ripple from "vue-ripple-directive";

import { heightTransition } from "@core/mixins/ui/transition";
import axios from "axios";

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BCardTitle,
    BCardFooter,
    BCardText,
    BForm,
    BLink,
    BMediaAside,
    BImg,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BMediaBody,
    BButton,
    BFormGroup,
    BFormInput,
    BProgress,
    BBadge,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      TabloItems: [],
      isTabloItem: true,
      addNew: true,

      email: "",
      password: "",
      id: null,

      snowOption: {
        theme: "snow",
      },
    };
  },
  watch: {},
  mounted() {},

  created() {
    this.getAllData();
    this.$router.beforeEach(async (to, from, next) => {
      const isAuthenticated = localStorage.getItem("token");

      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !isAuthenticated
      ) {
        // Bu sayfa kimlik doğrulama gerektiriyor ve kullanıcı giriş yapmamışsa
        next("/login");
      } else {
        next();
      }
    });
  },

  methods: {
    //
    getAllData() {
      axios
        .get("/api/Users/GetList")
        .then((result) => {
          if (result.data.success) {
            this.TabloItems = result.data.data;
        //    console.log(this.TabloItems);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Get Catch'e düştü", error);
        });
    },
    //Create/Post
    saveButton() {
      const newSliderInfo = {
        email: this.email,
        password: this.password,
      };
      axios
        .post("/api/Users/Create", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("GÜNCELLENDİ", {
              title: `ANASAYFA`,
              variant: "success",
            });
          } else {
            this.$bvToast.toast("GÜNCELLENEMEDİ", {
              title: `ANASAYFA`,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },
    //Update/Post
    updateBtn() {
      const newSliderInfo = {
        id: this.id,
        email: this.email,
        password: this.password,
      };

      axios
        .post("/api/Users/Update", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("GÜNCELLENDİ", {
              title: `ANASAYFA`,
              variant: "success",
            });
          } else {
            this.$bvToast.toast("GÜNCELLENEMEDİ", {
              title: `ANASAYFA`,
              variant: "danger",
            });
          }
          this.getAllData();
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },

    editModal(item) {
    //  console.log(item);
      this.email = item.email;
      this.password = item.password;
      this.id = item.id;
    },
    deleteModal(item) {
      this.id = item.id;
    },
    //delete/Post
    deleteData() {
      axios
        .post(`/api/Users/Delete/${this.id}`)
        .then(() => {
          this.getAllData();
        })
        .catch((error) => {
          console.error("Delete Hatası", error);
        });
    },
    clearData() {
      this.email = null;
      this.password = null;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.cardStyle {
  border: 1px solid grey;
  background-color: rgba(140, 235, 153, 0.13);
}
.titleStyle {
  text-align: left;
  color: black;
}
</style>
